import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
//import ExploraMenuBar from '../menu/ExploraMenuBar';
import NavBar from '../menu/NavDropDown/NavBar';
import secureLocalStorage from 'react-secure-storage';
import B08Results from './B08Results';
import B08Edit from './B08Edit';
import B08DropDownFilters from './B08DropDownFilters';
import SplitPane, { Pane } from 'split-pane-react';
import './B08.css';

const B08Main = () => {
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [srlRefSearchFilter, setSrlRefSearchFilter] = useState(null);
  const [srlRefDropDownFilter, setSrlRefDropDownFilter] = useState(null);
  const [dataLists, setdataLists] = useState([]);
  const [dataBcaRefTranslated, setdataBcaRefTranslated] = useState([]);
  const [reloadGrid,setReloadGrid]=useState(false);
  const [selectedValues, setSelectedValues] = useState({});

  const handleSelectChange = (id, value) => {
    console.log("B08Main handleSelectChange id ",id)
    console.log("B08Main handleSelectChange value ",value)
    setSelectedValues({
      ...selectedValues,
      [id]: value
    });
  };

  const makeAxiosRequest = async (token, url) => {
    //console.log("makeAxiosRequest",url)
    try {
        const config = {
            method: 'get',
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          window.location = '/login';
        }else{
            throw error;
        }
    }
  };

  const populateListIDFields = useCallback(async (listID) => {
    //console.log("B08Main populateListIDFields", metaDataApplication?.applicationCode);
    //console.log("B08Main populateListIDFields listID", listID);
    //
    const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
    const user = secureLocalStorage.getItem('userID');
    const data = await makeAxiosRequest(
      tokenAuth.token,
      'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetListIDFields?screenCode=B08' +
        '&domainChildCode=' +
        selectedMenuItem.domainChildCode +
        '&listID=' + listID +
        '&userID=' + user
    );
    setdataLists(data);
    //console.log("data from id fields",data);
    //const dropDownlist = data.filter((item) => item.graphicTypeCode === "Dropdown");
    //console.log("data from dropDownlist",dropDownlist);
  },[selectedMenuItem]);

  const populateBcaRefTranslated = useCallback(async () => {
    //console.log("B08Main populateBcaRefTranslated", metaDataApplication?.applicationCode);
    //console.log("B08Main populateBcaRefTranslated selectedValue", selectedValue);
    //console.log("srlRefDropDownFilter",srlRefDropDownFilter);
    //console.log("srlRefSearchFilter",srlRefSearchFilter);

    if (srlRefDropDownFilter!==null&&srlRefDropDownFilter!==undefined&&srlRefDropDownFilter!==""){
      //console.log("srlRefDropDownFilter!==null&&srlRefDropDownFilter!==undefined&&srlRefDropDownFilter!==''");
      const urlSearchFilter = srlRefSearchFilter ? `&SrlRefSearchFilter=${srlRefSearchFilter}` : '&SrlRefSearchFilter=null';
      const urlDropDownFilter = srlRefDropDownFilter ? `&srlRefDropdownFilter=${srlRefDropDownFilter}` : '&srlRefDropdownFilter=null';
  
      //console.log("urlSearchFilter",urlSearchFilter);
      const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
      const user = secureLocalStorage.getItem('userID');
      const url = 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetbcaRefTranslated?listID='+selectedValue.listID+
                  '&applicationLanguageID=1'+
                  '&calendarToApply=null'+
                  '&srlRefColumnFilter=null'+
                  '&userId='+user+
                  urlDropDownFilter+
                  '&currentRefSort=null'+
                  '&SrlListDomainChildCode=null'+
                  '&SrlRefTopRowsFilter=null'+
                  urlSearchFilter
  
      //console.log("url rest ",url);
      const data = await makeAxiosRequest(
        tokenAuth.token,url
      );
      setdataBcaRefTranslated(data);
      //console.log("data from bcaRefTranslated",data);
    }//else{console.log("srlRefDropDownFilter!==null&&srlRefDropDownFilter!==undefined&&srlRefDropDownFilter!=='' => else");}
    

  },[selectedValue,srlRefDropDownFilter,srlRefSearchFilter]);

  const pairNull = (id) => {
    //console.log("B08Main test pair null - id ",id);
    //console.log(selectedValues[id]);
    const res = selectedValues[id]===undefined ? true : selectedValues[id]===null ? true : false; 
    //console.log("B08Main test pair null - res ",res);
    return res;
  };

  const constructDropDownFilter = async () => {
    //console.log("B08Main constructDropDownFilter",dataLists? "OK":"KO");
    //console.log("B08Main selectedValues ",selectedValues);
    //construct searchfilter
    const dropDownFilters = dataLists.filter((item) => item.graphicTypeCode === "Dropdown"&&item.hideField==="N"&&item.moduleCode==="B08-24")
    if (selectedValues.length===0||selectedValues===null){
      const initializedArray = Array.from({ length: dropDownFilters.length }, (_, index) => {
        // Use Array.from to create an array of the specified length
        // Use the index parameter of the map function to generate unique values for each object
        return {
          id: index + 1, // Assuming you want IDs starting from 1
          value: `Object ${index + 1}` // Example value property
        };
      });
      setSelectedValues(initializedArray);
      console.log(initializedArray);
    }
    const searchFilter= dropDownFilters.map((element) => {
      let retVal = ""; // Use let instead of const to allow reassignment
      // Concatenate the string and update retVal 
      if (pairNull(element.columnName)){
        //console.log("B08Main pair null set default for ",element.columnName);
        setSelectedValues({
          ...selectedValues,
          [element.columnName]: element.lovDefaultRefCode
        });

        if (element.lovDefaultRefCode!==null){
          retVal = retVal.concat(" AND " + element.columnName + " IN ('" + element.lovDefaultRefCode + "','Common')");
        }
      }else{
        //values are not null
        retVal = retVal.concat(" AND " + element.columnName + " IN ('" + selectedValues[element.columnName] + "','Common')");
      }

      return retVal;
    });
    //console.log("SrlRefSearchFilter",searchFilter);
    // Join the array of strings into a single string
    const concatenatedString = searchFilter.join("");
    setSrlRefDropDownFilter(concatenatedString);
    setSrlRefSearchFilter(null);

    //console.log(srlRefDropDownFilter);
  };//,[dataLists]);

  useEffect(() => {
    //console.log("useEffect 1 B08Main");
    const searchParams = new URLSearchParams(location.search);
    const parsedMetaDataApplication = JSON.parse(
      decodeURIComponent(searchParams.get('metaDataApplication'))
    );

    // Update metaDataApplication state when it changes
    setSelectedMenuItem(parsedMetaDataApplication);
    console.log("parsedMetaDataApplication ",parsedMetaDataApplication);

  }, [location.search]);

  useEffect(() => {
    //console.log("useEffect 2 B08Main",selectedMenuItem);
    //console.log("useEffect 2 B08Main Selected Option: ",selectedValues);
    const metaDataApplication=selectedMenuItem;
    
    if (metaDataApplication && selectedValue) {
      populateListIDFields(selectedValue.listID).then(() => {constructDropDownFilter()}).then(() => {populateBcaRefTranslated()});
      //populateBcaRefTranslated();
      setReloadGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadGrid,selectedValue,selectedMenuItem,srlRefDropDownFilter,srlRefSearchFilter,populateBcaRefTranslated,populateListIDFields]);

  // Callback function to handle selected value from SubSubMenu
  const handleSelectedValue = (selectedItem) => {
    //console.log("handleSelectedValue",dataLists);
    // Update the selected value in the state or perform any other 
    setSelectedValue(selectedItem);

    console.log(selectedItem);
    

    if ((!dataLists || dataLists.length === 0)&&selectedValue) {
      //console.log("handleSelectedValue dataLists==null");
      populateListIDFields(selectedValue.listID).then(() => {constructDropDownFilter()});
    }else{
      //console.log("handleSelectedValue else");
      populateListIDFields(selectedItem.listID).then(() => {constructDropDownFilter()});
    }

  };

  const [sizes, setSizes] = useState([100, '5%', 'auto']);

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    float: 'left'
  };

  return (
    <div>
      {/* Pass the handleMenuItemSelect function to ExploraMenu */}
      <NavBar metaDataApplication={selectedMenuItem} onSelectedValue={handleSelectedValue}/>
      <B08DropDownFilters key={selectedValue?.listID} dropDownLists={dataLists.filter((item) => item.graphicTypeCode === "Dropdown")} setReloadGrid={setReloadGrid} selectedValues={selectedValues} onSelectChange={handleSelectChange}/>
      <div style={{ height: 800 }}>
        <SplitPane
            split='vertical'
            sizes={sizes}
            onChange={setSizes}
          >
            <Pane>
              <div style={{ ...layoutCSS }}>
                {/* Left pane content */}
                {/* Display dataLists */}
                <B08Results dataLists={dataLists} dataBcaRefTranslated={dataBcaRefTranslated} />
              </div>
            </Pane>
            <Pane>
              <div style={{ ...layoutCSS, background: '#d5d7d9' }}>
                <div id="containerB08" className="form-horizontal">
                  <div id="colB08" className="form-horizontal">
                    {/* Right pane content */}
                    <B08Edit dataLists={dataLists} dataBcaRefTranslated={dataBcaRefTranslated} />
                    </div>
                </div>
              </div>
            </Pane>
          </SplitPane>
      </div>
      {/* Display the selected menu item or index */}
      <p>Selected Menu Item: {selectedValue?.listName} ListID: {selectedValue?.listID} domainChildCode: {selectedMenuItem?.domainChildCode}</p>
    </div>
  );
};

export default B08Main;